@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'OpenSans';
    src: url('@assets/fonts/open-sans/OpenSans-Regular.ttf');
}

@font-face {
    font-family: 'Inter';
    src: url('@assets/fonts/inter/Inter-VariableFont_opsz,wght.ttf');
}

/* Custom Icon */

@font-face {
    font-family: 'icomoon';
    src: url('./assets/LogoIcon/icomoon.eot?9del63');
    src:
        url('./assets/LogoIcon/icomoon.eot?9del63#iefix')
            format('embedded-opentype'),
        url('./assets/LogoIcon/icomoon.ttf?9del63') format('truetype'),
        url('./assets/LogoIcon/icomoon.woff?9del63') format('woff'),
        url('./assets/LogoIcon/icomoon.svg?9del63#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@font-face {
    font-family: 'icomoon';
    src: url('./assets/customIcon/icomoon.eot?vqdh3y');
    src:
        url('./assets/customIcon/icomoon.eot?vqdh3y#iefix')
            format('embedded-opentype'),
        url('./assets/customIcon/icomoon.ttf?vqdh3y') format('truetype'),
        url('./assets/customIcon/icomoon.woff?vqdh3y') format('woff'),
        url('./assets/customIcon/icomoon.svg?vqdh3y#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-APIIcon:before {
    content: '\e900';
}

.icon-CorePlatformIcon:before {
    content: '\e901';
}

.icon-ManufacturerIcon:before {
    content: '\e902';
}

.icon-FoodandBeverageIcon:before {
    content: '\e903';
}

.icon-PharmaceuticalIcon:before {
    content: '\e904';
}

.icon-AutomotiveIcon:before {
    content: '\e905';
}

.icon-RetailIcon:before {
    content: '\e906';
}

.icon-ContructionSuppliesIcon:before {
    content: '\e907';
}

.icon-E-commerceCompaniesIcon:before {
    content: '\e908';
}

.icon-ElectronicsIcon:before {
    content: '\e909';
}

.icon-ForwardingIcon:before {
    content: '\e90a';
    font-size: 22px;
}

.icon-OtherIndustriesIcon:before {
    content: '\e90b';
}

.icon-AccountingIcon:before {
    content: '\e90c';
}

.icon-LinkedinIcon:before {
    content: '\e90d';
}

.icon-OrdersIcon:before {
    content: '\e90e';
    font-size: 22px;
}

.icon-WarehouseIcon:before {
    content: '\e90f';
}

.icon-InstagramIcon:before {
    content: '\e910';
}

.icon-XIcon:before {
    content: '\e911';
}

.icon-CRMIcon:before {
    content: '\e912';
}

.icon-Logos_LogLines:before {
    content: '\e918';
}

/* End Custom Icon */

* {
    font-family: 'Inter', sans-serif;
    margin: 0;
    padding: 0;
}

*::-webkit-scrollbar {
    width: 0px !important;
    height: 8px !important;
    margin-bottom: 50px;
}

*::-webkit-scrollbar-thumb {
    background-color: var(--loglines-gray-v2);
    border-radius: 14px;
}

*::-webkit-scrollbar-thumb:hover {
    background-color: var(--loglines-gray-v3);
    border-radius: 15px !important;
}

::-webkit-scrollbar-thumb:horizontal {
    background-color: var(--loglines-gray-v2);
    border-radius: 14px;
}

::-webkit-scrollbar-thumb:horizontal:hover {
    background-color: var(--loglines-gray-v3);
    border-radius: 15px !important;
    cursor: pointer;
}

.scrollable-content::-webkit-scrollbar * {
    background: transparent;
}

.scrollable-content::-webkit-scrollbar-thumb {
    background: transparent;
}

@layer base {
    body {
        color: var(--loglines-gray-v6);
    }
}

@keyframes slide-in {
    0% {
        margin-top: -50px;
    }

    100% {
        margin-top: 0px;
    }
}

@keyframes slide-out {
    0% {
        margin-top: 0px;
    }

    100% {
        margin-top: -100px;
    }
}

/* Logistical Container */
.container-global {
    @apply border-solid border rounded-[10px];
    background-color: var(--loglines-white);
    border-color: var(--loglines-gray-v3);
}

.content-full-height {
    @apply h-[calc(100vh-6.5rem)] sm:!h-[calc(100vh-5.2rem)];
}

.box-shadow {
    box-shadow: 0px 10px 10px #cbcaca;
}

.required:before {
    content: ' *';
    color: var(--loglines-red-v3);
    padding-right: 1px;
}

.bebas-neue-regular {
    font-family: 'Bebas Neue', sans-serif;
    font-weight: 400;
    font-style: normal;
}

.inter {
    font-family: 'Inter', sans-serif;
    font-style: normal;
}
