body.bright-sky-blue-themes {
  /* ------ Dynamic Loglines Colors ------ */
  --loglines-brand-v1: #f0fafe;
  --loglines-brand-v2: #cceffc;
  --loglines-brand-v3: #99dffa;
  --loglines-brand-v4: #66d0f7;
  --loglines-brand-v5: #00b0f2;
  --loglines-brand-v6: #008dc2;
  --loglines-brand-v7: #006a91;

  /* Bg Color */
  .themes-bg-brand-v1 {
    background-color: var(--loglines-brand-v1);
  }
  .themes-bg-brand-v2 {
    background-color: var(--loglines-brand-v2);
  }
  .themes-bg-brand-v3 {
    background-color: var(--loglines-brand-v3);
  }
  .themes-bg-brand-v4 {
    background-color: var(--loglines-brand-v4);
  }
  .themes-bg-brand-v5 {
    background-color: var(--loglines-brand-v5);
  }
  .themes-bg-brand-v6 {
    background-color: var(--loglines-brand-v6);
  }
  .themes-bg-brand-v7 {
    background-color: var(--loglines-brand-v7);
  }

  /* Bg Hover Color */
  .themes-bg-hover-brand-v1:hover {
    background-color: var(--loglines-brand-v1);
  }
  .themes-bg-hover-brand-v2:hover {
    background-color: var(--loglines-brand-v2);
  }
  .themes-bg-hover-brand-v3:hover {
    background-color: var(--loglines-brand-v3);
  }
  .themes-bg-hover-brand-v4:hover {
    background-color: var(--loglines-brand-v4);
    color: var(--loglines-white);
  }
  .themes-bg-hover-brand-v5:hover {
    background-color: var(--loglines-brand-v5);
    color: var(--loglines-white);
  }
  .themes-bg-hover-brand-v6:hover {
    background-color: var(--loglines-brand-v6);
    color: var(--loglines-white);
  }
  .themes-bg-hover-brand-v7:hover {
    background-color: var(--loglines-brand-v7);
    color: var(--loglines-white);
  }

  /* Text Color */
  .themes-text-brand-v1 {
    color: var(--loglines-brand-v1);
  }
  .themes-text-brand-v2 {
    color: var(--loglines-brand-v2);
  }
  .themes-text-brand-v3 {
    color: var(--loglines-brand-v3);
  }
  .themes-text-brand-v4 {
    color: var(--loglines-brand-v4);
  }
  .themes-text-brand-v5 {
    color: var(--loglines-brand-v5);
  }
  .themes-text-brand-v6 {
    color: var(--loglines-brand-v6);
  }
  .themes-text-brand-v7 {
    color: var(--loglines-brand-v7);
  }

  
  /* Text Color */
  .themes-text-hover-brand-v1:hover {
    color: var(--loglines-brand-v1);
  }
  .themes-text-hover-brand-v2:hover {
    color: var(--loglines-brand-v2);
  }
  .themes-text-hover-brand-v3:hover {
    color: var(--loglines-brand-v3);
  }
  .themes-text-hover-brand-v4:hover {
    color: var(--loglines-brand-v4);
  }
  .themes-text-hover-brand-v5:hover {
    color: var(--loglines-brand-v5);
  }
  .themes-text-hover-brand-v6:hover {
    color: var(--loglines-brand-v6);
  }
  .themes-text-hover-brand-v7:hover {
    color: var(--loglines-brand-v7);
  }


  /* Border Color */
  .themes-border-brand-v1 {
    border-color: var(--loglines-brand-v1);
  }
  .themes-border-brand-v2 {
    border-color: var(--loglines-brand-v2);
  }
  .themes-border-brand-v3 {
    border-color: var(--loglines-brand-v3);
  }
  .themes-border-brand-v4 {
    border-color: var(--loglines-brand-v4);
  }
  .themes-border-brand-v5 {
    border-color: var(--loglines-brand-v5);
  }
  .themes-border-brand-v6 {
    border-color: var(--loglines-brand-v6);
  }
  .themes-border-brand-v7 {
    border-color: var(--loglines-brand-v7);
  }

  /* Content Background */
  .themes-content {
    background-color: var(--loglines-gray-v1);
  }

  /* Header */
  .themes-icon-brand path {
    fill: var(--loglines-brand-v5);
  }
  .themes-header {
    background-color: var(--loglines-brand-v5);
    color: var(--loglines-white);
  }
  .themes-header-profile {
    background-color: var(--loglines-white);
  }
  .themes-header-profile-menu {
    color: var(--loglines-gray-v6);
  }
  .themes-header-profile-menu:hover {
    background-color: var(--loglines-gray-v2);
  }
  .themes-avatar {
    color: var(--loglines-brand-v5);
    background-color: var(--loglines-brand-v1);
  }
  .themes-breadcrumb {
    color: var(--loglines-white);
  }

  /* Sidebar */
  .themes-sidebar-icon path {
    fill: var(--loglines-white);
  }
  .themes-sidebar-icon-collapse path {
    fill: var(--loglines-white);
  }
  .themes-sidebar-collapse-button {
    color: var(--loglines-white);
  }
  .themes-sidebar {
    background-color: var(--loglines-white);
  }
  .themes-sidebar-header {
    background-color: var(--loglines-brand-v5);
  }

  /* Menu */
  .themes-menu {
    background-color: var(--loglines-white);
    color: var(--loglines-gray-v6);
  }
  .themes-menu-active {
    color: var(--loglines-white);
    background-color: var(--loglines-brand-v5);
  }
  .themes-menu:hover {
    color: var(--loglines-brand-v5);
    background-color: var(--loglines-brand-v1);
  }
  .themes-menu:active,
  .themes-menu:focus {
    color: var(--loglines-white);
    background-color: var(--loglines-brand-v3);
  }
  .themes-menu-tooltip {
    color: var(--loglines-white);
    background-color: var(--loglines-gray-v6);
  }

  /* Sub Menu */
  .themes-submenu-active:hover,
  .themes-submenu:hover {
    background-color: var(--loglines-brand-v1);
    color: var(--loglines-brand-v5);
  }
  .themes-submenu-active {
    background-color: var(--loglines-white);
    color: var(--loglines-brand-v5);
  }

  /* Status Color */
  /* span -> total data */
  .themes-status-brand {
    color: var(--loglines-gray-v5);
  }
  .themes-status-brand span {
    background-color: var(--loglines-brand-v1);
    color: var(--loglines-brand-v7);
  }
  .themes-status-brand:hover {
    color: var(--loglines-brand-v5);
    span {
      background-color: var(--loglines-brand-v1);
      color: var(--loglines-brand-v7);
    }
  }
  .themes-status-brand.active,
  .themes-status-brand.active:hover {
    color: var(--loglines-brand-v5);
  }
  .themes-status-brand.active span,
  .themes-status-brand.active span:hover {
    /* active not impact   */
    background-color: var(--loglines-brand-v4);
    color: var(--loglines-white);
  }
  .themes-status-brand.active .bottom-line {
    background-color: var(--loglines-brand-v4);
  }

  /* Text Link */
  .themes-text-link {
    cursor: pointer;
    color: var(--loglines-brand-v5);
  }
  .themes-text-link:hover {
    color: var(--loglines-brand-v6);
  }
}
